.wholesale-menu {
    display: inline-block;
    width: 100%;
    margin-bottom: 111px;
    .wholesale-menu-holder {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 30px);
        margin-left: -15px;
        .menu-item {
            width: 33.333%;
            height: auto;
            box-sizing: border-box;
            padding: 0 15px;
            .menu-item-holder {
                display: inline-block;
                width: 100%;
                height: 100%;
                background: #F5FCF6;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                position: relative;
                text-decoration: none;
                color: $gray-base;
                overflow: hidden;
                min-height: 368px;
                box-sizing: border-box;
                padding: 52px 10px 0;
                &:hover {
                    color: $brand-primary;
                    .image {
                        img {
                            transform: scale(1.1);
                            transition: all 300ms;
                        }
                    }
                }
                .name {
                    display: inline-block;
                    width: 100%;
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 150%;
                    text-align: center;
                    margin-bottom: 10px;
                }

                .desc {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                    color: $gray;
                }

                .icon {
                    position: absolute;
                    left: 31px;
                    bottom: 124px;
                    z-index: 2;
                    width: 70px;
                    height: 70px;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                .image {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -165px;
                    z-index: 1;
                    border-radius: 50%;
                    width: 350px;
                    height: 350px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 300ms;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/wholesale_menu";
@import "../../media/mobile/includes/common/wholesale_menu";
