.reviews-block {
    display: inline-block;
    width: 100%;
    background: #F9F9F9;
    margin-bottom: 77px;
    &.lower-margin {
        margin-bottom: 38px;
    }
    &.full {
        margin-bottom: 60px;
        .reviews-block-holder {
            padding: 60px 0 56px;
            .reviews-slider {
                width: 100%;
            }
        }
    }
	.reviews-block-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 82px 0 68px;
		.reviews-slider {
            display: inline-block;
            float: left;
            width: 65.7%;
			.heading {
                display: inline-block;
                width: 100%;
                margin-bottom: 28px;
                &.small {
                    .text {
                        font-size: 30px;
                        line-height: 150%;
                    }

                    .link {
                        padding-top: 14px;
                    }

                    .controlls {
                        margin-right: -4px;
                        padding-top: 3px
                    }
                }
				.text {
                    display: inline-block;
                    float: left;
                    max-width: 65%;
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 36px;
                    line-height: 150%;

				}

				.link {
                    display: inline-block;
                    float: left;
                    margin-left: 31px;
                    padding-top: 23px;
					a {
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 130%;
                        color: $brand-primary;
                        text-decoration: underline;
                        text-decoration-color: currentColor;
                        &:hover {
                            text-decoration-color: transparent;
                        }
					}
				}

				.controlls {
                    display: inline-block;
                    float: right;
                    margin-right: -7px;
                    padding-top: 16px;
					.prev, .next {
                        display: none;
                        float: left;
                        margin: 0 5px;
                        position: relative;
                        left: auto;
                        right: auto;
                        top: auto;
                        transform: none;
                        &.slick-arrow {
                            display: inline-block;
                        }
						svg {

						}
					}

					.next {

						svg {

						}
					}
				}

				.nav {

				}
			}

			.reviews-slider-holder {


			}
		}

		.isntagram-col {
            display: inline-block;
            float: left;
            width: 34.3%;
			.isntagram-col-holder {
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding-left: 38px;
			}
		}
	}
}

.instagram-widget {
    display: inline-block;
    width: 100%;
	.instagram-widget-holder {
        display: inline-block;
        width: 100%;
		.heading {
            display: block;
            width: 100%;
            height: 130px;
            background: linear-gradient(240.15deg, #260091 7.55%, #C7398E 39.48%, #FF4D4D 68.22%, #FFC909 99.56%);
            border-radius: 20px 20px 0px 0px;
            margin-bottom: 7px;
            padding: 36px 0 0 40px;
            box-sizing: border-box;
			.icon {
                width: 50px;
                height: 50px;
                margin-right: 15px;
                float: left;
				svg {

				}
			}

			.text {
                color: white;
                padding: 7px 21px 0 0;
                float: left;
				.message {
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 130%;
				}

				.channel {
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 150%;
				}
			}
		}

		.images {
            display: inline-block;
            width: calc(100% + 6px);
            margin-left: -3px;
			.image {
                display: inline-block;
                float: left;
                width: 33.333%;
                box-sizing: border-box;
                padding: 0 3px;
                margin-bottom: 5px;
                a {
                    display: inline-block;
                    width: 100%;
                    height: 110px;
                    overflow: hidden;
                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: all 300ms;
                        }
                    }
					img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 300ms;
					}
				}
			}
		}
	}
}

.review-preview {
    display: inline-block;
    width: 100%;
    height: 400px;
    .review-preview-holder {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: white;
        box-shadow: 0px 15px 20px rgba(220, 220, 220, 0.3);
        border-radius: 20px;
        box-sizing: border-box;
        overflow: hidden;
        padding: 30px;
        .person {
            display: inline-block;
            width: 100%;
            margin-bottom: 20px;
            .image {
                display: inline-block;
                width: 50px;
                height: 50px;
                float: left;
                position: relative;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-image: url('../img/quotes.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-clip: border-box;
                }
                img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            .data {
                display: inline-block;
                width: calc(100% - 50px);
                box-sizing: border-box;
                padding-left: 20px;
                .date {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 150%;
                    color: $gray;
                    margin-bottom: 6px;
                }

                .name {
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 130%;
                }
            }
        }

        .review-text {
            display: inline-block;
            width: 100%;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            height: 240px;
            overflow: hidden;
            margin-bottom: 7px;
            &.cover {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 120px;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
                    perspective-origin: bottom;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
        }

        .action {
            display: inline-block;
            width: 100%;
            a {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
                text-decoration: underline;
                text-decoration-color: currentColor;
                &:hover {
                    text-decoration-color: transparent;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/index/reviews_block";
@import "../../media/mobile/includes/index/reviews_block";
