.inline-page-action {
    display: inline-block;
    width: 100%;
    margin: 36px 0 0;
    &.no-margin {
        margin: 0;
    }
    &.left {
        .inline-page-action-holder {
            justify-content: flex-start;
        }
    }
    &.right {
        .inline-page-action-holder {
            justify-content: flex-end;
        }
    }
    .inline-page-action-holder {
        display: flex;
        justify-content: center;
        a.btn {
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 130%;
            border: 1px solid rgba(0, 121, 64, 0.2);
            box-sizing: border-box;
            border-radius: 70px;
            padding: 14px 21px;
            min-width: 279px;
            text-align: center;
            &:hover {
                border: 1px solid $brand-primary;
            }
        }
    }
}

@import "../../media/tablet/includes/common/action";
@import "../../media/mobile/includes/common/action";
