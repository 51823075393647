@media (max-width: $screen-sm) {
    // sm-view
    .delivery-and-payment {
        margin: 1px 0 28px;
        .delivery-and-payment-holder {
            p {}

            h3, .h3 {
                margin: 20px 0 8px
            }
            h4, .h4 {
                margin: 0px 0 9px;
            }

            ul.dots {
                margin-bottom: 21px;
                li {
                    p {

                    }
                }
            }

            .payment-methods {
                .payment-method {
                    .icon {

                    }

                    .text {
                        padding: 0;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
