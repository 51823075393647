@media (max-width: $screen-lg) {
    // lg-md view
    .article-detail {
        margin: 0 0 9px;
        .article-detail-holder {
            img {
                max-width: 210px;
            }
            img.full {
                max-width: 100%;
            }

            h1, .h1 {
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                line-height: 150%;
            }

            p {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
            }

            p.accent {
                font-size: 16px;
            }

            ul.dots {

            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
