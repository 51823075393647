.banners {
    position: absolute;
    left: -1px;
    top: 19px;
    .banner {
        position: relative;
        padding: 2px 12px 2px 11px;
        &.popular {
            .bkg {
                color: #FF5C6E;
            }
        }
        .text {
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: white;
            position: relative;
            z-index: 2;
        }
        .bkg {
            position: absolute;
            left: 0;
            top: 0;
            color: $brand-primary;
            width: 100%;
            height: 100%;
            z-index: 1;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
