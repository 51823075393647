@media (max-width: $screen-lg) {
    // lg-md view
    .reviews {
        .reviews-holder {
            .note {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 130%;
                margin-bottom: 16px;
                margin-left: 2px;
            }

            .actions {
                margin-bottom: 27px;
            }
        }
    }

    .reviews-list {
        .reviews-list-holder {
            .review {
                margin-bottom: 29px;
                .review-holder {
                    .person {
                        .image {}

                        .data {
                            .date {}

                            .name {}
                        }
                    }

                    .review-text {
                        p {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
