.article-detail {
    display: inline-block;
    width: 100%;
    margin: 0 0 21px;
    .article-detail-holder {
        display: inline-block;
        width: 100%;
        img.right {
            float: right;
            padding: 0 0 0 0;
        }
        img.left {
            float: left;
        }
        img.full {
            width: 100%;
        }

        h1, .h1 {
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 150%;
            margin: 0 0 20px;
        }

        p.accent {
            font-size: 18px;
            strong {
                font-weight: 600;
            }
        }

        ul.dots {
            position: relative;
            z-index: 1;
            li {

                p {

                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/article_detail";
@import "../../media/mobile/includes/common/article_detail";
