@media (max-width: $screen-lg) {
    // lg-md view
    .faq {
        margin-top: -1px;
        margin-bottom: 19px;    
        .faq-holder {
            .qa-list {
                .qa-element {
                    margin-bottom: 2px;
                    .qa-element-holder {
                        a.list-opener {
                            padding: 0 0 0 43px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 150%;
                            .icon {
                                left: 0px;
                            }
                        }
                        .qa-element-contents {
                            .qa-element-contents-holder {
                                padding: 10px 0 18px 43px;
                                p {
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 14px;
                                    line-height: 150%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
