@media (max-width: $screen-lg) {
    // lg-md view
    .bouquet.full {
        height: calc(25vw + 8px + 98px + 40px + 40px);
        .bouquet-holder {
            .bouquet-container {
                padding: 20px 20px 20px;
                .image {
                    height: 25vw;
                    margin-bottom: 8px;
                    .banners {
                        top: -11px;
                        left: -20px;
                    }
                    a {
                        img {

                        }
                    }
                }

                .info {
                    height: 98px;
                    .name {
                        margin-bottom: 6px;
                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 150%;
                        }
                    }

                    .desc {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 130%;
                    }
                }

                .action {
                    .price {
                        padding-top: 5px;
                        .old-value {
                            display: none;
                        }
                        .value {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 21px;
                            line-height: 150%;
                        }
                    }

                    .buy {
                        width: 48%;
                        .btn {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 130%;
                            padding: 8px 0;
                        }
                    }
                }
            }
        }
    }
    .bouquet {
        height: 47vw;
        .bouquet-holder {
            .bouquet-container {
                .image {
                    height: 25vw;
                    margin-bottom: 8px;
                    .banners {
                        top: -11px;
                    }
                    a {
                        img {

                        }
                    }
                }

                .info {
                    height: 10.8vw;
                    .name {
                        margin-bottom: 6px;
                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 150%;
                        }
                    }

                    .desc {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 130%;
                    }
                }

                .action {
                    .price {
                        .old-value {
                            font-size: 16px;
                            line-height: 16px;
                        }
                        .value {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 21px;
                            line-height: 24px;
                        }
                    }

                    .buy {
                        .btn {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 130%;
                            padding: 8px 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
