@media (max-width: $screen-sm) {
    // sm-view
    .articles-list {
        margin-bottom: 38px;
        .articles-list-holder {
            .article-item {
                width: 100%;
                margin-bottom: 15px;
            }
        }
        .inline-page-action {
            margin: 0 0 31px;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
