.bouquet.full {
    height: 450px;
    .bouquet-holder {
        .bouquet-container {
            padding: 30px 30px 20px;
            .image {
                height: 260px;
                margin-bottom: 10px;
                .banners {
                    left: -30px;
                    top: -10px;
                }
            }

            .info {
                height: 74px;
                .name {
                    margin-bottom: 7px;
                }

                .desc {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 130%;
                }
            }

            .action {
                .price {
                    padding-top: 15px;
                    width: 48%;
                }

                .buy {
                    width: 52%;
                    .btn {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}
.bouquet {
    display: inline-block;
    width: 100%;
    height: 460px;
	.bouquet-holder {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        &:before {
            content: "";
            display: inline-block;
            box-sizing: border-box;
            border: 1px solid #DFE5EA;
            width: calc(100% + 1px);
            height: calc(100% + 1px);
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: 1;
        }
		.bouquet-container {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
            box-sizing: border-box;
            padding: 20px 20px 20px;
			.image {
                display: inline-block;
                width: 100%;
                height: 235px;
                margin-bottom: 15px;
                position: relative;
                .banners {
                    left: -20px;
                    top: -1px;
                }
				a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: all 300ms;
                        }
                    }
					img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 300ms;
					}
				}
			}

			.info {
                display: inline-block;
                width: 100%;
                height: 125px;
                overflow: hidden;
				.name {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 4px;
					a {
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 150%;
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
					}
				}

				.desc {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                    color: $gray;
				}
			}

			.action {
                display: inline-block;
                width: 100%;
				.price {
                    display: inline-block;
                    width: 50%;
                    float: left;
                    padding-top: 10px;
                    .old-value {
                        display: inline-block;
                        float: left;
                        font-family: $accent_font;
                        font-size: 16px;
                        line-height: 24px;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: line-through;
                        color: $gray;
                        margin-right: 12px;
                    }
                    .value {
                        display: inline-block;
                        float: left;
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 24px;
                    }
				}

				.buy {
                    display: inline-block;
                    width: 48%;
                    float: right;
					.btn {
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 150%;
                        border-radius: 30px;
                        padding: 8px 0;
                        width: 100%;
                        text-align: center;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/bouquet";
@import "../../media/mobile/includes/catalog/bouquet";
