.reviews {
    display: inline-block;
    width: 100%;
    margin: 2px 0 59px;
	.reviews-holder {
        display: inline-block;
        width: 100%;
		.note {
            display: inline-block;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            margin-bottom: 30px;
		}

		.actions {
            display: inline-block;
            width: 100%;
            margin-bottom: 39px;
			.btn {
                width: 228px;
                padding: 16px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                text-transform: uppercase;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
				.icon {
                    margin-right: 10px;
					svg {

					}
				}

				.text {

				}
			}
		}

        .inline-page-action {
            margin: 15px 0 28px;
        }
	}
}

.reviews-list {
    display: inline-block;
    width: 100%;
	.reviews-list-holder {
        display: inline-block;
        width: 100%;
		.review {
            display: inline-block;
            width: 100%;
            margin-bottom: 28px;
			.review-holder {
                display: inline-block;
                width: 100%;
                box-shadow: 0px 15px 20px rgba(220, 220, 220, 0.3);
                border-radius: 20px;
                box-sizing: border-box;
                padding: 30px 30px 20px;
                .person {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 20px;
                    .image {
                        display: inline-block;
                        width: 50px;
                        height: 50px;
                        float: left;
                        position: relative;
                        &:before {
                            content: "";
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background-image: url('../img/quotes.svg');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-clip: border-box;
                        }
                        img {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                    }

                    .data {
                        display: inline-block;
                        width: calc(100% - 50px);
                        box-sizing: border-box;
                        padding-left: 20px;
                        .date {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 150%;
                            color: $gray;
                            margin-bottom: 6px;
                        }

                        .name {
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 130%;
                        }
                    }
                }

				.review-text {
                    display: inline-block;
                    width: 100%;
					p {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 150%;
                        margin: 0 0 11px;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/reviews";
@import "../../media/mobile/includes/common/reviews";
