@media (max-width: $screen-sm) {
    // sm-view
    .dealers {
        margin: 0 0 18px;
        .dealers-holder {
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 150%;
            }

            .dealers-list {
                .dealers-list-holder {
                    .dealer {
                        width: 100%;
                        margin-bottom: 17px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
