@media (max-width: $screen-sm) {
    // sm-view
    .toggable-space {
        .space {
            .space-holder {

                .page-table {

                    table {
                        thead {
                            tr {
                                th {
                                    padding: 9px 5px 9px 5px;
                                }
                            }
                        }

                        tbody {

                            tr {
                                td {
                                    padding: 9px 5px 9px 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
