@media (max-width: $screen-lg) {
    // lg-md view
    .toggable-space {
        margin: 0 0 7px;
        .toggle {
            a {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
                .icon {}

                .text {
                    padding: 1px 0 0;
                    text-decoration: underline;
                    text-decoration-style: dashed;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
