@media (max-width: $screen-sm) {
    // sm-view
    .greenhouse-promo.partnership {
        &.standalone {
            margin-bottom: 38px;
        }
        .greenhouse-promo-holder {
            .promo {
                .text {
                    width: 100%;
                    box-sizing: border-box;
                    padding-right: 0;
                    margin: 0 0 10px;
                    p {

                    }
                    ul {
                        li {
                            &:before {
                                top: 6px;
                                left: 1px;
                            }
                        }
                    }
                }
            }
        }
    }

    .greenhouse-promo {
        &.standalone {
            margin-bottom: 20px;
        }
        .greenhouse-promo-holder {
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 150%;
                margin-bottom: 11px;
            }

            .promo {
                .file {
                    width: 100%;
                    height: 59vw;
                    .download {
                        a {
                            .icon {}

                            .file-text {
                                .name {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 150%;
                                }

                                .add {

                                }
                            }
                        }
                    }
                }

                .text {
                    p {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 150%;
                    }

                    ul {
                        li {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            padding-left: 30px;
                            &:before {
                                top: 2px;
                                left: -2px;
                            }
                        }
                    }
                }

                .images {
                    .images-slider {
                        height: 82.8125vw;
                        padding: 0 0 29px;
                        .slick-slider {
                            .slick-list {
                                padding-right: 0;
                                .slick-track {
                                    .slick-slide {
                                        &.slick-current {
                                            >div {
                                                .image {
                                                    img {
                                                        width: 90.0625vw;
                                                        height: 82.8125vw;
                                                    }
                                                }

                                            }
                                        }
                                        >div {
                                            .image {

                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .imaged-slider-holder {

                            .image {
                                height: 100%;
                                img {
                                    width: 90.0625vw;
                                    height: 82.8125vw;
                                }
                            }
    					}
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
