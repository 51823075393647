@media (max-width: $screen-sm) {
    // sm-view
    .not-found {
        margin: 37px 0 40px;
        .not-found-holder {
            .four-zero-four {
                img {

                }
            }

            .text {
                padding: 0;
                margin: 0 0 19px;
            }

            .action {
                .btn {

                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
