.question-form.type-2 {
    .question-form-holder {
        background: #F7F7F7;
        overflow: visible;
        .top-bottom-lines {
            background-image: none;
        }

        .left-right-lines {}

        .question-form-inner {
            padding: 45px 40px;
            .heading {
                font-size: 30px;
                margin-bottom: 10px;
            }

            .desc {
                margin-bottom: 20px;
            }

            form {
				.form-group {

					.form-col {

						.form-field {

							.input {

								.name {

								}

								input, textarea {
                                    background: white;
								}
							}
						}
					}
				}

				.action {

					.submit {
                        width: 237px;
						.btn {

						}
					}

					.agree-col {
                        width: 100%;
                        padding-left: 0;
                        padding-top: 13px;
						a {

						}
					}
				}
			}
        }
    }
}
.question-form {
    display: inline-block;
    width: 100%;
    margin-bottom: 79px;
	.question-form-holder {
        display: inline-block;
        width: 100%;
        position: relative;
        overflow: hidden;
		.top-bottom-lines {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            background-image: url('../img/question-form-bkg.png');
            background-size: auto;
            background-position: right bottom;
            background-repeat: no-repeat;
            background-clip: border-box;
            .inner-container {
                &:before, &:after {
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 5px;
                    background-image: url('../img/color-line-horizontal.png');
                    background-position: left;
                    background-repeat: repeat-x;
                    background-size: 100%;
                    background-clip: border-box;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                &:after {
                    top: auto;
                    bottom: 0;
                }
            }

		}

		.left-right-lines {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            .inner-container {
                &:before, &:after {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 100%;
                    background-image: url('../img/color-line-side.png');
                    background-position: top;
                    background-repeat: repeat-y;
                    background-size: 100%;
                    background-clip: border-box;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                &:after {
                    left: auto;
                    right: 0;
                }
            }

		}

		.question-form-inner {
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            padding: 50px 35% 64px 52px;
            position: relative;
            z-index: 2;
			.heading {
                font-family: $accent_font;
                font-style: normal;
                font-weight: normal;
                font-size: 36px;
                line-height: 150%;
                margin-bottom: 21px;
                &.center {
                    text-align: center;
                }
			}

			.desc {
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 150%;
                margin-bottom: 33px;
                &.center {
                    text-align: center;
                }
			}

			form {
                display: inline-block;
                width: 100%;
				.form-group {

					.form-col {

						.form-field {

							.input {

								.name {

								}

								input {

								}
							}
						}
					}
				}

                .upload-file {
                    display: flex;
                    flex-wrap: nowrap;
                    width: 100%;
                    margin-bottom: 20px;
                	.action {
                        display: inline-block;
                        float: left;
                        margin-right: 22px;
                		.btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 237px;
                            height: 55px;
                            padding: 9;
                            border-radius: 10px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 130%;
                		}
                	}

                	.status {
                        display: inline-flex;
                        align-items: center;
                        float: left;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 150%;
                	}
                }

				.action {

					.submit {

						.btn {

						}
					}

					.agree-col {

						a {

						}
					}
				}
                .action.center {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .agree-col {
                        text-align: center;
                    }
                }
			}
		}
	}
}

@import "../../media/tablet/includes/index/question_form";
@import "../../media/mobile/includes/index/question_form";
