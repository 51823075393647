@media (max-width: $screen-lg) {
    // lg-md view
    .dealers {
        margin: 0 0 6px;
        .dealers-holder {
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
                margin: 0 0 20px;
            }

            .dealers-list {
                .dealers-list-holder {
                    .dealer {
                        width: 50%;
                        margin-bottom: 27px;
                        .dealer-holder {
                            padding: 22px 19px 0px;
                            height: 251px;
                            .place {
                                margin: 0 0 14px;
                                .icon {
                                    padding-top: 5px;
                                }

                                .text {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 150%;
                                }
                            }

                            .desc {
                                p {
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 14px;
                                    line-height: 150%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
