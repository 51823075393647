.dealers {
    display: inline-block;
    width: 100%;
    margin: 0 0 47px;
    .dealers-holder {
        display: inline-block;
        width: 100%;
        .heading {
            display: inline-block;
            width: 100%;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 30px;
            line-height: 150%;
            margin: 0 0 30px;
        }

        .dealers-list {
            display: inline-block;
            width: 100%;
            .dealers-list-holder {
                display: inline-block;
                width: calc(100% + 30px);
                margin-left: -15px;
                .dealer {
                    display: inline-block;
                    float: left;
                    width: 33.333%;
                    box-sizing: border-box;
                    padding: 0 15px;
                    margin-bottom: 28px;
                    .dealer-holder {
                        display: inline-block;
                        width: 100%;
                        border: 1px solid #DFE5EA;
                        box-sizing: border-box;
                        border-radius: 10px;
                        padding: 20px 30px 0px;
                        height: 280px;
                        overflow: hidden;
                        .place {
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 12px;
                            .icon {
                                display: inline-block;
                                float: left;
                                width: 15px;
                                height: 15px;
                                padding-top: 9px;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .text {
                                display: inline-block;
                                float: left;
                                width: calc(100% - 15px);
                                font-style: normal;
                                font-weight: 600;
                                font-size: 21px;
                                line-height: 150%;
                                box-sizing: border-box;
                                padding-left: 10px;
                            }
                        }

                        .desc {

                            p {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 150%;
                                margin: 0 0 10px;
                                color: $gray;
                                a {
                                    color: $gray;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/dealers";
@import "../../media/mobile/includes/common/dealers";
