@media (max-width: $screen-lg) {
    // lg-md view
    .reviews-block {
        margin: 0 0 40px;
        &.full {
            margin-bottom: 40px;
            .reviews-block-holder {
                padding: 40px 0 7px;
                .reviews-slider {
                    padding: 0 0 39px;
                }
            }
        }
        .reviews-block-holder {
            padding: 40px 0 39px;
            .reviews-slider {
                width: 100%;
                padding: 0 0 48px;
                margin: 0 0 32px;
                .heading {
                    margin-bottom: 17px;
                    &.small {
                        .text {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 24px;
                            line-height: 150%;
                        }

                        .link {
                            padding-top: 11px;
                            margin: 0;
                        }
                    }
                    .text {
                        width: 100%;
                        max-width: 100%;
                        text-align: center;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 150%;
                    }

                    .link {
                        margin: 10px 0 0;
                        padding: 0;
                        width: 100%;
                        text-align: center;
                        a {

                        }
                    }
                }
            }

            .isntagram-col {
                width: 100%;
                .isntagram-col-holder {
                    padding: 0;
                }
            }
        }
    }

    .review-preview {
        height: 350px;
        .review-preview-holder {
            .person {
                .image {

                }

                .data {
                    .date {

                    }

                    .name {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 130%;
                    }
                }
            }

            .review-text {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
                height: 190px;
            }

            .action {

            }
        }
    }

    .instagram-widget {
        .instagram-widget-holder {
            .heading {
                border-radius: 0;
                width: 100%;
                height: 100%;
                flex-direction: column;
                margin: 0;
                padding: 0;
                .icon {
                    width: 100%;
                    text-align: center;
                    margin: 0 0 20px;
                    svg {
                        width: 50px;
                        height: 50px;
                    }
                }

                .text {
                    padding: 0;
                    width: 100%;
                    text-align: center;
                    .message {

                    }

                    .channel {

                    }
                }
            }

            .images {
                width: 100%;
                margin: -2px;
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: repeat(2, 14.324vw);
                grid-column-gap: 6px;
                grid-row-gap: 6px;
                .image {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    margin: 0;
                    &.image1 { grid-area: 1 / 1 / 2 / 2; }
                    &.image2 { grid-area: 2 / 1 / 3 / 2; }
                    &.image3 { grid-area: 1 / 2 / 2 / 3; }
                    &.image4 { grid-area: 2 / 2 / 3 / 3; }
                    &.image-big {grid-area: 1 / 3 / 3 / 5;}
                    &.image6 { grid-area: 1 / 5 / 2 / 6; }
                    &.image7 { grid-area: 1 / 6 / 2 / 7; }
                    &.image8 { grid-area: 2 / 5 / 3 / 6; }
                    &.image9 { grid-area: 2 / 6 / 3 / 7; }
                    >a {
                        height: 100%;
                        &.heading {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }

                    }
                }
            }


        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
