@media (max-width: $screen-lg) {
    // lg-md view
    .question-form.type-2 {
        margin-bottom: 55px;
        .question-form-holder {
            .question-form-inner {
                padding: 35px 40px;
                .heading {
                    font-size: 24px;
                }

                .desc {}

                form {


                    .action {

    					.submit {
                            width: 100%;
                            text-align: center;
                            margin: 0 0 11px;
    						.btn {
                                width: 290px;
    						}
    					}

    					.agree-col {
                            width: 100%;
                            display: block;
                            margin: 0 auto;
                            text-align: center;
                            padding: 0;
                            float: none;
    						a {

    						}
    					}
    				}
                }
            }
        }
    }
    .question-form {
        margin-bottom: 37px;
        .question-form-holder {
            width: 111.305%;
            margin-left: -5.65%;
            .top-bottom-lines {
                background: none;
                .inner-container {
                    &:before, &:after {
                        background-image: url('../img/color-line-horizontal-md.png');
                    }
                }
            }
            .left-right-lines {
                display: none;
            }
            .question-form-inner {
                padding: 36px 6.4% 35px;
                .heading {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 150%;
                    text-align: center;
                    margin-bottom: 10px;
                }

                .desc {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                    text-align: center;
                    margin-bottom: 20px;
                }

                form {

                    .form-group {

    					.form-col {

    						.form-field {

    							.input {

    								.name {

    								}

    								input {

    								}
    							}
    						}
    					}
    				}

                    .upload-file {
                        flex-wrap: wrap;
                        .action {
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            margin: 0;
                            .btn {
                                width: 290px;
                            }
                        }

                        .status {
                            width: 100%;
                            margin: 10px 0 0;
                            justify-content: center;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                        }
                    }

                    .action {

    					.submit {
                            width: 100%;
                            text-align: center;
                            margin: 0 0 22px;
    						.btn {
                                width: 320px;
    						}
    					}

    					.agree-col {
                            width: 320px;
                            display: block;
                            margin: 0 auto;
                            text-align: center;
                            padding: 0;
                            float: none;
    						a {

    						}
    					}
    				}
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
