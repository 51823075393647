.catalog-item {
    display: inline-block;
    width: 100%;
    height: 440px;
    box-sizing: border-box;
    position: relative;
    &:hover {
        z-index: 10;
        &:before {
            height: calc(100% + 1px + 123px);
            box-shadow: 0px 10px 20px rgba(150, 163, 173, 0.25);
            transition: height 300ms, box-shadow 300ms;
        }
        .catalog-item-holder {
            height: calc(100% - 2px + 122px);
            transition: height 300ms;
            .catalog-container {

                .bucket-size {
                    opacity: 1;
                    transition: all 300ms;
                    transform: translateX(0);
                }

                .image {

                }

                .info {
                    .name {

                    }

                    .price {
                        opacity: 0;
                        height: 0;
                        transition: opacity 300ms, height 300ms;
                    }
                }

                .popup-info {
                    opacity: 1;
                    height: 182px;
                    transition: opacity 300ms, height 300ms;
                }

            }
        }
    }
    &:before {
        content: "";
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        box-sizing: border-box;
        border: 1px solid #DFE5EA;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        background: white;
        transition: height 300ms, box-shadow 300ms;
    }
	.catalog-item-holder {
        display: inline-block;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        left: 1px;
        top: 1px;
        z-index: 2;
        box-sizing: border-box;
        transition: height 300ms;
        .catalog-container {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 3;
            box-sizing: border-box;
            padding: 30px;
    		.bucket-size {
                position: absolute;
                top: 15px;
                right: 13px;
                opacity: 0;
                transition: all 300ms;
                transform: translateX(5px);
    			.size {
                    display: inline-block;
                    float: left;
                    position: relative;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 130%;
                    color: $gray;
                    margin-right: 6px;
                    .arrows {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -22px;
                        svg {

                        }
                    }
    			}

    			.popup-note {
                    display: inline-block;
                    float: left;
    			}
    		}

    		.image {
                display: inline-block;
                width: 100%;
                margin-bottom: 15px;
    			a {
                    display: flex;
                    width: 100%;
                    height: 254px;
                    align-items: flex-end;
                    justify-content: center;
    				img {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
    				}
    			}
    		}

    		.info {
                display: inline-block;
                width: 100%;
    			.name {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    height: 72px;
                    overflow: hidden;
    				a {
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
    				}
    			}

    			.price {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    height: 36px;
                    margin-top: -6px;
                    transition: opacity 300ms, height 300ms;
    				.old-value {
                        display: inline-block;
                        float: left;
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 16px;
                        margin-right: 6px;
                        text-decoration: line-through;
                        color: $gray;
                        text-transform: uppercase;
    				}

    				.value {
                        display: inline-block;
                        float: left;
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 24px;
                        text-transform: uppercase;
    				}
    			}
    		}

    		.popup-info {
                display: inline-block;
                width: 100%;
                height: 0;
                opacity: 0;
                overflow: hidden;
                transition: opacity 300ms, height 300ms;
    			.sizes {
                    display: inline-block;
                    width: calc(100% + 10px);
                    margin-left: -5px;
                    margin-top: 5px;
                    margin-bottom: 13px;
    				.size {
                        display: inline-block;
                        float: left;
                        box-sizing: border-box;
                        padding: 0 5px;
                        width: 33.333%;
    					.btn {
                            width: 100%;
                            padding: 8px 0;
                            box-sizing: border-box;
                            text-align: center;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 130%;
                            border-radius: 20px;
                            background: transparent;
                            border: 1px solid #DFE5EA;
                            color: $gray;
                            &:hover {
                                background: $brand-primary;
                                border: 1px solid $brand-primary;
                                color: white;
                            }
                            &.selected {
                                border: 1px solid $brand-primary;
                                background: transparent;
                                color: $brand-primary;
                                &:hover {
                                    border: 1px solid $brand-primary;
                                    background: transparent;
                                    color: $brand-primary;
                                    cursor: default;
                                }
                            }
                            &.disabled {
                                background: #F0F4F6;
                                border: 1px solid #F0F4F6;
                                color: $gray-light;
                                &:hover {
                                    background: #F0F4F6;
                                    border: 1px solid #F0F4F6;
                                    color: $gray-light;
                                    cursor: default;
                                }
                            }
    					}
    				}
    			}

    			.order {

    				.order-details {
                        display: inline-block;
                        width: 50%;
                        float: left;
    					.amount-block {
                            display: inline-block;
                            width: 100%;
                            margin-bottom: 19px;
    						.icon {
                                display: inline-block;
                                float: left;
                                width: 35px;
                                padding-top: 7px;
    							svg {

    							}
    						}

    						.amount {
                                display: inline-block;
                                float: left;
                                width: calc(100% - 35px);
    							.amount-counter {

    								.decrease {

    									svg {

    									}
    								}

    								input {

    								}

    								.increase {

    									svg {

    									}
    								}
    							}
    						}
    					}

                        .price {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-end;
                            height: 36px;
                            margin-top: 0;
            				.old-value {
                                display: inline-block;
                                float: left;
                                font-family: $accent_font;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 16px;
                                margin-right: 6px;
                                text-decoration: line-through;
                                color: $gray;
                                text-transform: uppercase;
                                max-width: 54px;
            				}

            				.value {
                                display: inline-block;
                                float: left;
                                font-family: $accent_font;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 24px;
                                line-height: 24px;
                                text-transform: uppercase;
            				}
            			}
    				}

    				.order-actions {
                        display: inline-block;
                        width: 50%;
                        float: left;
    					.one-clik-buy {
                            display: inline-block;
                            width: 100%;
                            text-align: right;
                            padding-top: 12px;
                            margin-bottom: 24px;
    						a {
                                font-family: $accent_font;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 130%;
                                color: $brand-primary;
                                position: relative;
                                margin-right: 3px;
                                &:hover {
                                    color: $brand-primary-accent;
                                    &:after {
                                        opacity: 0;
                                        transform: translateX(3px);
                                        transition: all 300ms;
                                    }
                                }
                                &:after {
                                    content: "";
                                    display: inline-block;
                                    width: 100%;
                                    height: 0;
                                    border-top: 1px dashed currentColor;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    transition: all 300ms;
                                }
    						}
    					}

    					.add-to-cart {
                            display: inline-block;
                            width: 100%;
                            box-sizing: border-box;
                            padding-left: 5px;
    						.btn {
                                display: inline-block;
                                width: 100%;
                                text-align: center;
                                padding: 11px 0;
                                font-family: $accent_font;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 150%;
                                border-radius: 30px;
    						}
    					}
    				}
    			}
    		}
        }
	}
}

@import "../../media/tablet/includes/catalog/item";
@import "../../media/mobile/includes/catalog/item";
