@media (max-width: $screen-sm) {
    // sm-view
    .wholesale-menu {
        margin-bottom: 21px;
        .wholesale-menu-holder {
            .menu-item {
                width: 100%;
                margin: 0 0 19px;
                .menu-item-holder {
                    padding: 50px 10px 0;
                    min-height: 369px;
                    .name {}

                    .desc {}

                    .icon {
                        left: 1px;
                        bottom: 126px;
                    }

                    .image {}
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
