.popup-note {
    display: inline-block;
    position: relative;
	a {
        display: inline-block;
        width: 14px;
        height: 15px;
        border-radius: 50%;
        &:hover, &:focus {
            ~.note-text {
                opacity: 1;
                z-index: 2;
                transition: z-index 0ms, opacity 300ms;
                transition-delay: z-index 0ms, opacity 150ms;
            }
        }
		svg {

		}
	}

	.note-text {
        display: inline-block;
        width: 135px;
        position: absolute;
        top: calc(100% + 18px);
        right: -4px;
        opacity: 0;
        z-index: -1;
        transition: z-index 0ms, opacity 300ms;
        transition-delay: z-index 300ms, opacity 0ms;
		.note-text-holder {
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            padding: 5px 14px 5px 10px;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 130%;
            background: #F7F7F7;
            border: 1px solid #DFE5EA;
            box-shadow: 0px 5px 4px rgba(150, 163, 173, 0.15);
            position: relative;
            border-radius: 10px;
            &:before {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 4px 8px 4px;
                border-color: transparent transparent #f7f7f7 transparent;
                position: absolute;
                right: 5px;
                top: -8px;
                z-index: 2;
            }
             &:after {
                 content: "";
                 display: inline-block;
                 width: 0;
                 height: 0;
                 border-style: solid;
                 border-width: 0 4.5px 9px 4.5px;
                 border-color: transparent transparent #DFE5EA transparent;
                 position: absolute;
                 right: 4.5px;
                 top: -9px;
                 z-index: 1;
             }
		}
	}
}
