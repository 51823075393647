@media (max-width: $screen-lg) {
    // lg-md view
    .wholesale-menu {
        margin-bottom: 51px;
        .wholesale-menu-holder {
            .menu-item {
                width: 50%;
                margin: 0 0 41px;
                .menu-item-holder {
                    padding: 41px 10px 0;
                    min-height: 358px;
                    .name {}

                    .desc {}

                    .icon {
                        left: 20px;
                    }

                    .image {}
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
