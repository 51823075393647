@media (max-width: $screen-lg) {
    // lg-md view
    .articles-list {
        margin-bottom: 38px;
        .articles-list-holder {
            .article-item {
                width: 50%;
                margin-bottom: 26px;
            }
        }
        .inline-page-action {
            margin: 0 0 31px;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
