@media (max-width: $screen-sm) {
    // sm-view
    .article-detail {
        .article-detail-holder {
            h1, .h1 {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
            }

            p {

            }

            p.accent {
                margin: 0 0 20px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
