@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-item {
        height: calc(25.3vw + 7.82vw + 40px + 54px);
        &:hover {
            z-index: 10;
            &:before {
                height: calc(100% + 1px);
                box-shadow: none;
            }
            .catalog-item-holder {
                height: calc(100% - 2px);
                .catalog-container {

                    .bucket-size {
                        opacity: 0;
                    }

                    .image {
                        a {

                        }
                    }

                    .info {
                        .name {

                        }

                        .price {
                            opacity: 1;
                            height: 36px;
                        }
                    }

                    .popup-info {
                        opacity: 0;
                        height: 0px;
                    }

                }
            }
        }
        .catalog-item-holder {
            .catalog-container {
                padding: 24px 30px 30px;
                .bucket-size {
                    display: none;
                }

                .image {
                    a {
                        height: 25.3vw;
                    }
                }

                .info {
                    .name {
                        height: 7.82vw;
                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 150%;
                        }
                    }

                    .price {
                        .old-value {
                            font-size: 16px;
                            line-height: 16px;
                        }

                        .value {
                            font-size: 21px;
                            line-height: 21px;
                        }
                    }
                }

                .popup-info {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
