@media (max-width: $screen-lg) {
    // lg-md view
    .articles-block {
        margin: 0 0 31px;
        .articles-block-holder {
            padding: 40px 0 39px;
            .articles-slider {
                padding: 0 0 35px;
                margin: 0;
                .heading {
                    margin-bottom: 27px;
                    .text {
                        width: 100%;
                        max-width: 100%;
                        text-align: center;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 150%;
                    }

                    .link {
                        margin: 10px 0 0;
                        padding: 0;
                        width: 100%;
                        text-align: center;
                        a {

                        }
                    }
                }
            }
        }
    }

    .article-item.boarded {
        .article-item-holder {
            .info {
                padding: 17px 21px 0;
            }
        }
    }
    .article-item {
        .article-item-holder {
            .image {
                height: 26vw;
                a {
                    img {}
                }
            }

            .info {
                padding: 20px 21px 20px;
                .name {
                    a {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 150%;
                    }
                }

                .desc {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
