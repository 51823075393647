@media (max-width: $screen-lg) {
    // lg-md view
    .delivery-and-payment {
        margin: -9px 0 28px;
        .delivery-and-payment-holder {
            p {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
            }

            h3, .h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                margin: 20px 0 10px;
            }

            h4, .h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
            }

            ul.dots {
                margin-bottom: 26px;
            }

            .payment-methods {
                .payment-method {
                    .icon {}

                    .text {
                        padding: 4px 0 0;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }
            }

            .page-table {
                margin: 0 0 18px;
                table {
                    thead {
                        tr {
                            th {
                                padding: 9px 0 9px 9px;
                            }
                        }
                    }

                    tbody {

                        tr {
                            td {
                                padding: 9px 0 8px 10px;
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 150%;
                            }
                        }
                    }
                }
            }

            .page-note {
                padding: 13px 14px 5px;
                .page-note-holder {
                    p {

                        strong {

                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
