.company-points {
    display: inline-block;
    width: 100%;
    margin: 49px 0 47px;
    &.adjusted-margin {
        margin: 36px 0 58px;
    }
	.company-points-holder {
        display: flex;
        justify-content: space-between;
        width: calc(100% + 19px);
		.point {
            width: 30%;
			.point-holder {
                display: inline-block;
                width: 100%;
				.icon {
                    display: inline-block;
                    float: left;
                    width: 50px;
                    height: 50px;
					svg {
                        width: 100%;
                        height: 100%;
                    }
				}

				.text {
                    display: inline-block;
                    float: left;
                    width: calc(100% - 50px);
                    box-sizing: border-box;
                    padding: 0 0 0 20px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 130%;
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/company_points";
@import "../../media/mobile/includes/index/company_points";
