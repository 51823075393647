.articles-block {
    display: inline-block;
    width: 100%;
    background: #F9F9F9;
    margin-bottom: 79px;
	.articles-block-holder {
        display: inline-block;
        width: 100%;
        padding: 80px 0 76px;
		.articles-slider {
            display: inline-block;
            width: 100%;
            .heading {
                display: inline-block;
                width: 100%;
                margin-bottom: 28px;
				.text {
                    display: inline-block;
                    float: left;
                    max-width: 65%;
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 36px;
                    line-height: 150%;
				}

				.link {
                    display: inline-block;
                    float: left;
                    margin-left: 25px;
                    padding-top: 23px;
					a {
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 130%;
                        color: $brand-primary;
                        text-decoration: underline;
                        text-decoration-color: currentColor;
                        &:hover {
                            text-decoration-color: transparent;
                        }
					}
				}

				.controlls {
                    display: inline-block;
                    float: right;
                    margin-right: -5px;
                    padding-top: 7px;
					.prev, .next {
                        display: none;
                        float: left;
                        margin: 0 5px;
                        position: relative;
                        left: auto;
                        right: auto;
                        top: auto;
                        transform: none;
                        &.slick-arrow {
                            display: inline-block;
                        }
						svg {

						}
					}

					.next {

						svg {

						}
					}
				}

				.nav {

				}
			}

			.articles-slider-holder {


			}
		}
	}
}

.article-item.boarded {
    .article-item-holder {
        .image {
            a {

            }
        }

        .info {
            .name {
                a {

                }
            }

            .desc {

            }
        }
    }
}
.article-item {
    display: inline-block;
    width: 100%;
    .article-item-holder {
        display: inline-block;
        width: 100%;
        background: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #DFE5EA;
        .image {
            display: inline-block;
            width: 100%;
            height: 249px;
            a {
                display: inline-block;
                width: 100%;
                height: 100%;
                overflow: hidden;
                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: all 300ms;
                    }
                }
                img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    transition: all 300ms;
                    object-fit: cover; 
                }
            }
        }

        .info {
            display: inline-block;
            width: 100%;
            height: 208px;
            box-sizing: border-box;
            padding: 20px;
            position: relative;
            &:after {
                content: "";
                display: inline-block;
                width: 100%;
                height: 75px;
                position: absolute;
                left: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
            }
            .name {
                display: inline-block;
                width: 100%;
                margin-bottom: 10px;
                a {
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    line-height: 150%;
                    color: $gray-base;
                    &:hover {
                        color: $brand-primary;
                    }
                }
            }

            .desc {
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
                color: $gray;
            }
        }
    }
}

@import "../../media/tablet/includes/index/articles_slider";
@import "../../media/mobile/includes/index/articles_slider";
