.greenhouse-promo.partnership {
    &.standalone {
        margin-bottom: 32px;
    }
    .greenhouse-promo-holder {
        .promo {
            .text {
                width: calc(100% - 310px);
                box-sizing: border-box;
                padding-right: 46px;
            }
        }
    }
}

.greenhouse-promo {
    display: inline-block;
    width: 100%;
    margin-bottom: 82px;
    &.standalone {
        margin-bottom: 0;
        .greenhouse-promo-holder {
            margin-left: 0;
        }
    }
	.greenhouse-promo-holder {
        display: inline-block;
        width: 100%;
        margin-left: 10px;
		.heading {
            display: inline-block;
            width: 100%;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 150%;
            box-sizing: border-box;
            padding: 0;
            margin-bottom: 21px;
		}

		.promo {
            display: inline-block;
            width: 100%;

            .file {
                display: inline-block;
                float: left;
                width: 310px;
                height: 300px;
                .download {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    a {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        color: $gray-base;
                        border: 1px solid #BEDCCE;
                        box-sizing: border-box;
                        border-radius: 10px;
                        padding: 10px 0 0;
                        &:hover {
                            background: $brand-primary;
                            border: 1px solid $brand-primary;
                            .icon {
                                color: white;
                                transition: all 300ms;
                            }

                            .file-text {
                                .name {
                                    color: white;
                                    transition: all 300ms;
                                }

                                .add {
                                    color: white;
                                    transition: all 300ms;
                                }
                            }
                        }
                        .icon {
                            color: $brand-primary;
                            margin-bottom: 30px;
                            svg {

                            }
                        }
                        .file-text {
                            width: 100%;
                            .name {
                                width: 100%;
                                text-align: center;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 21px;
                                line-height: 130%;
                                color: $gray-base;
                                margin-bottom: 5px;
                            }
                            .add {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 130%;
                                color: $gray;
                                text-align: center;
                            }
                        }
                    }

                }
            }

			.text {
                display: inline-block;
                float: left;
                width: 50%;
                box-sizing: border-box;
                padding-right: 10px;
				p {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 150%;
				}

				ul {
                    display: inline-block;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    list-style: none;
					li {
                        display: inline-block;
                        width: 100%;
                        position: relative;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 150%;
                        box-sizing: border-box;
                        padding-left: 32px;
                        margin-bottom: 10px;
                        &:before {
                            content: "";
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            background-image: url('../img/list-leaf.png');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-clip: border-box;
                            position: absolute;
                            left: 0;
                            top: 8px;
                        }
					}
				}

                .inline-page-action {
                    margin: 18px 0 0;
                }
			}

			.images {
                display: inline-block;
                float: left;
                width: 50%;
                box-sizing: border-box;
                padding-left: 15px;
				.images-slider {
                    display: inline-block;
                    width: 100%;
                    height: 394px;
					.prev , .next {
                        top: auto;
                        left: auto;
                        right: 61px;
                        bottom: 0;
                        background: none;
                        border: none;
                        transform: none;
                        &:hover {
                            svg {
                                color: $brand-primary;
                            }
                        }
						svg {
                            width: 15px;
                            height: 10px;
                            color: $gray;
						}
                        &.slick-disabled {
                            opacity: 0.5;
                            svg {
                                color: $gray;
                            }
                        }
					}

					.next {
                        right: 0;
						svg {

						}
					}

					.nav {

					}

                    .slick-slider {
                        .slick-list {
                            box-sizing: border-box;
                            padding-right: 315px;
                            .slick-track {
                                .slick-slide {
                                    &.slick-current {
                                        &+.slick-slide {
                                            >div {
                                                .image {
                                                    z-index: 2;
                                                    img {
                                                        opacity: 0.5;
                                                    }
                                                }
                                            }
                                        }
                                        >div {
                                            .image {
                                                z-index: 3;
                                                img {
                                                    left: 0;
                                                    top: -14px;
                                                    width: 430px;
                                                    height: 395px;
                                                    opacity: 1;
                                                    transition: all 300ms;
                                                }
                                            }

                                        }
                                    }
                                    >div {
                                        .image {

                                        }
                                    }
                                }
                            }
                        }
                    }

					.imaged-slider-holder {
                        height: 100%;


                        .image {
                            position: relative;
                            z-index: 1;
                            img {
                                position: absolute;
                                left: 0;
                                top: 21px;
                                width: 315px;
                                height: 310px;
                                opacity: 0;
                                transition: all 300ms;
                            }
                        }
					}
				}
			}
		}

		.inline-page-action {

			.inline-page-action-holder {

				.btn {

				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/greenhouse_promo";
@import "../../media/mobile/includes/index/greenhouse_promo";
