.positions-list {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;
    .positions-list-holder {
        display: inline-block;
        width: 100%;
        .position {
            display: inline-block;
            width: 100%;
            border: 1px solid #DFE5EA;
            border-bottom: none;
            box-sizing: border-box;
            padding: 22px 30px 0 20px;
            min-height: 314px;
            &:last-of-type {
                border-bottom: 1px solid #DFE5EA;
            }
            .position-holder {
                display: inline-block;
                width: 100%;
                .main-info {
                    display: inline-block;
                    float: left;
                    width: calc(100% - 190px);
                    .main-info-holder {
                        display: inline-block;
                        width: 100%;
                        box-sizing: border-box;
                        padding-right: 75px;
                        .name {
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 150%;
                            margin: 0 0 9px;
                        }

                        .desc {
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            margin: 0 0 11px;
                        }

                        .requirements {
                            display: inline-block;
                            width: 100%;
                            .heading {
                                display: inline-block;
                                width: 100%;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 130%;
                                margin: 0 0 9px;
                            }

                            ul.dots {

                                li {
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 14px;
                                    line-height: 150%;
                                    &:before {
                                        left: -16px;
                                        top: 9px;
                                    }
                                }
                            }
                        }
                    }
                }

                .sub-info {
                    display: inline-block;
                    float: left;
                    width: 190px;
                    .sub-info-holder {
                        display: inline-block;
                        width: 100%;
                        .salary {
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 150%;
                            text-align: center;
                            margin: 0 0 21px;
                        }

                        .action {
                            display: inline-block;
                            width: 100%;
                            .btn {
                                display: inline-block;
                                width: 100%;
                                padding: 12px 0;
                                border-radius: 10px;
                                text-align: center;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                text-align: center;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/positions";
@import "../../media/mobile/includes/common/positions";
