.message-box {
    display: inline-block;
    width: 100%;
    margin-bottom: 36px;
    .message-box-holder {
        display: inline-block;
        width: 100%;
        background: #F5FCF6;
        border: 1px dashed #00914C;
        box-sizing: border-box;
        padding: 30px 30px 30px 66px;
        font-family: $accent_font;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        background-image: url(../img/list-leaf.png);
        background-position: 29px 37px;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-clip: border-box;
    }
}

@import "../../media/tablet/includes/common/message_box";
@import "../../media/mobile/includes/common/message_box";
