@media (max-width: $screen-sm) {
    // sm-view
    .contacts {
        margin-bottom: 37px;
        .contacts-holder {
            .infoes {
                width: 100%;
                margin: 0 0 5px;
            }

            .map {
                width: 100%;
                .map-holder {
                    height: 119vw;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
