@media (max-width: $screen-sm) {
    // sm-view
    .reviews {
        margin: 0px 0 59px;
        .reviews-holder {
            .note {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                margin-left: 1px;
                margin-bottom: 19px;
            }

            .actions {
                margin-bottom: 9px;
                .btn {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
