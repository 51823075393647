.wholesale-catalog {
    display: inline-block;
    width: 100%;
    margin: 0 0 28px;
    .wholesale-catalog-holder {
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
        .wholesale-item {
            display: inline-block;
            float: left;
            width: 50%;
            box-sizing: border-box;
            padding: 0 15px;
            margin-bottom: 28px;
            .wholesale-item-holder {
                display: inline-block;
                width: 100%;
                height: 300px;
                box-sizing: border-box;
                padding: 20px;
                border: 1px solid #DFE5EA;
                border-radius: 10px;
                .image {
                    display: inline-block;
                    float: left;
                    width: 200px;
                    height: 100%;
                    a {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        &:hover {
                            img {
                                transform: scale(1.1);
                                transition: all 300ms;
                            }
                        }
                        img {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: all 300ms;
                        }
                    }
                }

                .text {
                    display: inline-block;
                    float: left;
                    width: calc(100% - 200px);
                    box-sizing: border-box;
                    padding-left: 20px;
                    .name {
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 6px;
                        a {
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 21px;
                            line-height: 150%;
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                            }
                        }
                    }

                    .desc {
                        display: inline-block;
                        width: 100%;
                        max-height: 188px;
                        overflow: hidden;
                        p {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            color: $gray;
                            margin: 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/wholesale_catalog";
@import "../../media/mobile/includes/common/wholesale_catalog";
