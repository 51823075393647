@media (max-width: $screen-sm) {
    // sm-view
    .wholesale-catalog {
        margin: 11px 0 28px;
        .wholesale-catalog-holder {
            .wholesale-item {
                margin-bottom: 18px;
                .wholesale-item-holder {
                    padding: 18px 18px;
                    height: auto;
                    .image {
                        width: 100%;
                        height: 53vw;
                        text-align: center;
                        margin: 0 0 20px;
                        a {
                            width: 51%;
                            height: 100%;
                        }
                    }
                    .text {
                        width: 100%;
                        padding: 0;
                        .name {
                            text-align: center;
                            a {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 150%;
                            }
                        }

                        .desc {
                            text-align: center;
                            p {

                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
