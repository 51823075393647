@media (max-width: $screen-sm) {
    // sm-view
    .faq {
        .faq-holder {
            .qa-list {
                .qa-element {
                    .qa-element-holder {
                        a.list-opener {
                            padding: 0 0 0 34px;
                            .icon {
                                top: 0;
                                left: 1px;
                                transform: none;
                            }
                        }
                        .qa-element-contents {
                            .qa-element-contents-holder {
                                padding: 10px 0 18px 34px;
                                p {

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
