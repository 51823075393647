@media (max-width: $screen-lg) {
    // lg-md view
    .message-box {
        margin-bottom: 41px;
        .message-box-holder {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            padding: 20px 30px 19px 55px;
            background-position: 19px 27px;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
