@media (max-width: $screen-sm) {
    // sm-view
    .articles-block {
        margin: 0 0 29px;
        .articles-block-holder {
            padding: 40px 0 39px;
            .articles-slider {
                padding: 0 0 10px;
                margin: 0;
                .heading {
                    margin-bottom: 16px;
                    .text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 21px;
                        line-height: 150%;
                    }

                    .link {
                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 130%;
                        }
                    }
                }
            }
        }
    }

    .article-item.boarded {
        .article-item-holder {
            .info {
                padding: 19px 19px 0;
            }
        }
    }
    .article-item {
        .article-item-holder {
            .image {
                height: 62vw;
                a {
                    img {}
                }
            }

            .info {
                padding: 20px 19px 0;
                height: 240px;
                .name {
                    a {

                    }
                }

                .desc {

                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
