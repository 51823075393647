@media (max-width: $screen-sm) {
    // sm-view
    .bouquet.full {
        height: calc(65vw + 16px + 98px + 40px + 40px);
        .bouquet-holder {
            .bouquet-container {
                .image {
                    height: 65vw;
                    margin-bottom: 16px;
                }

                .info {
                    height: 98px;
                }

                .action {
                    .price {
                        width: 56%;
                        padding-top: 8px;
                        .old-value {
                            display: inline-block;
                            width: auto;
                            float: left;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 21px;
                            line-height: 26px;
                        }
                        .value {
                            display: inline-block;
                            width: auto;
                            float: left;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 24px;
                            line-height: 24px;
                        }
                    }

                    .buy {
                        width: 44%;
                        .btn {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 130%;
                        }
                    }
                }
            }
        }
    }
    .bouquet {
        height: 125vw;
        .bouquet-holder {
            .bouquet-container {
                .image {
                    height: 65vw;
                    margin-bottom: 16px;
                }

                .info {
                    height: 26.8vw;
                }

                .action {
                    .price {
                        width: 56%;
                    }

                    .buy {
                        width: 44%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
