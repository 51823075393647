@media (max-width: $screen-sm) {
    // sm-view
    .reviews-block {
        &.full {
            margin-bottom: 40px;
            .reviews-block-holder {
                padding: 31px 0 0;
                .reviews-slider {
                    padding: 0 0 26px;
                }
            }
        }
        .reviews-block-holder {
            padding: 30px 0 23px;
            .reviews-slider {
                padding: 0 0 26px;
                margin: 0 0 30px;
                .heading {
                    &.small {
                        .text {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 21px;
                            line-height: 150%;
                        }

                        .link {
                            padding-top: 9px;
                            margin: 0;
                        }
                    }
                    .text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 21px;
                        line-height: 150%;
                    }

                    .link {
                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 130%;
                        }
                    }
                }
            }
        }
    }

    .instagram-widget {
        .instagram-widget-holder {
            .heading {
                width: 100%;
                height: 112px;
                flex-direction: row;
                border-radius: 20px 20px 0px 0px;
                padding: 0 15px 0 0;
                margin: 0 0 4px;
                display: flex !important;
                align-items: center;
                justify-content: center;
                .icon {
                    width: 50px;
                    height: 50px;
                    margin: 0;
                }

                .text {
                    width: auto;
                    padding: 0 0 0 14px;
                    .message {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 130%;
                    }

                    .channel {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 21px;
                        line-height: 150%;
                        margin: 2px 0 0 -16px;
                    }
                }
            }

            .images {
                width: calc(100% + 6px);
                margin-left: -3px;
                display: inline-block;
                .image {
                    width: 33.333%;
                    float: left;
                    box-sizing: border-box;
                    padding: 0 3px;
                    margin: 0 0 3px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
