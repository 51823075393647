@media (max-width: $screen-lg) {
    // lg-md view
    .contacts {
        margin-bottom: 26px;
        .contacts-holder {
            .infoes {
                width: calc(100% - 390px);
                .infoes-holder {
                    hr {
                        margin: 17px 0 21px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 150%;
                        strong {
                            a {
                                font-size: 16px;
                            }
                        }
                    }

                    .socials {
                        margin: 13px 0 0;
                        .text {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            margin-right: 6px;
                        }

                        .list {
                            ul {
                                li {

                                }
                            }
                        }
                    }

                    .shop-list {
                        margin: 11px 0 0;
                        .shop {
                            margin-bottom: 8px;
                            .shop-holder {
                                .icon {

                                }

                                .text {
                                    .address {
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 130%;
                                    }

                                    .phone {
                                        a {
                                            font-style: normal;
                                            font-weight: 300;
                                            font-size: 14px;
                                            line-height: 150%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .map {
                width: 390px;
                .map-holder {
                    height: 491px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
