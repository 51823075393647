@media (max-width: $screen-sm) {
    // sm-view
    .catalog-item {
        height: calc(76vw + 12px + 63px + 40px + 54px);
        .catalog-item-holder {
            .catalog-container {
                padding: 24px 29px 30px;
                .image {
                    margin-bottom: 12px;
                    a {
                        height: 76vw;
                    }
                }

                .info {
                    .name {
                        height: 63px;
                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 150%;
                        }
                    }

                    .price {
                        .old-value {
                            font-size: 16px;
                            line-height: 16px;
                        }

                        .value {
                            font-size: 24px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
