.item-slider-margin-fix {
    margin-top: -95px;
}

.items-slider-block.bouquets {
    .items-slider-block-holder {
        .item-slider {

            .slick-slider {
                .slick-list {
                    padding-bottom: 66px;
                    .slick-track {
                        .slick-slide {
                            >div {

                            }
                        }
                    }
                }
            }
        }
    }
}

.items-slider-block.margin-fix {
    .items-slider-block-holder {
        margin-left: -16px;
    }
}

.items-slider-block.small-heading {
    .items-slider-block-holder {
        .item-slider {
            .heading {
                .text {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 150%;
                }
            }
        }
    }
}

.items-slider-block {
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 2;
    .items-slider-block-holder {
        display: inline-block;
        width: calc(100% + 13px);
        .item-slider {
            box-sizing: border-box;
            padding: 0 0 0 16px;
            .slick-slider {
                .slick-list {
                    padding: 4px 2px;
                    box-sizing: border-box;
                    width: calc(100% + 4px);
                    margin-left: -2px;
                    padding-bottom: 150px;
                    .slick-track {
                        .slick-slide {
                            >div {

                            }
                        }
                    }
                }
            }

            .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 24px;
                .text {
                    display: inline-block;
                    float: left;
                    max-width: 65%;
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 30px;
                    line-height: 150%;
                }

                .link {
                    display: inline-block;
                    float: left;
                    margin-left: 31px;
                    padding-top: 23px;
                    a {
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 130%;
                        color: $brand-primary;
                        text-decoration: underline;
                        text-decoration-color: currentColor;
                        &:hover {
                            text-decoration-color: transparent;
                        }
                    }
                }

                .controlls {
                    display: inline-block;
                    float: right;
                    margin-right: -3px;
                    padding-top: 3px;
                    .prev, .next {
                        display: none;
                        float: left;
                        margin: 0 5px;
                        position: relative;
                        left: auto;
                        right: auto;
                        top: auto;
                        transform: none;
                        &.slick-arrow {
                            display: inline-block;
                        }
                        svg {

                        }
                    }

                    .next {

                        svg {

                        }
                    }
                }

                .nav {

                }
            }

            .item-slider-holder {

            }

        }
    }
}

@import "../../media/tablet/includes/catalog/items_slider";
@import "../../media/mobile/includes/catalog/items_slider";
