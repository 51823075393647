@media (max-width: $screen-lg) {
    // lg-md view
    .positions-list {
        .positions-list-holder {
            .position {
                padding: 20px 19px 20px 20px;
                .position-holder {
                    .main-info {
                        .main-info-holder {
                            padding-right: 50px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
