@media (max-width: $screen-lg) {
    // lg-md view
    .greenhouse-promo.partnership {
        &.standalone {
            margin-bottom: 19px;
        }
        .greenhouse-promo-holder {
            .promo {
                .text {
                    width: calc(100% - 210px);
                    box-sizing: border-box;
                    padding-right: 36px;
                    p {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 150%;
                    }
                    ul {
                        li {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            padding-left: 32px;
                        }
                    }
                }
            }
        }
    }

    .greenhouse-promo {
        margin-bottom: 28px;
        &.standalone {
            margin-bottom: 0px;
            .greenhouse-promo-holder {
                .promo {
                    .text {
                        margin: 0 0 9px;
                    }
                    .images {
                        .images-slider {

                        }
                    }
                }
            }
        }
        .greenhouse-promo-holder {
            margin-left: 0;
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
                text-align: center;
            }

            .promo {

                .file {
                    width: 210px;
                    height: 187px;
                    .download {
                        a {
                            padding: 0 0 0;
                            .icon {
                                width: 56px;
                                height: 60px;
                                margin-bottom: 11px;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .file-text {
                                .name {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 130%;
                                }
                                .add {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 130%;
                                }
                            }
                        }
                    }
                }

                .text {
                    width: 100%;
                    padding: 0;
                    margin: 0 0 18px;
                    ul {
                        li {
                            padding-left: 39px;
                            &:before {
                                top: 3px;
                            }
                        }
                    }

                    .inline-page-action {
                        margin: 10px 0 0;
                        &.left {
                            .inline-page-action-holder {
                                justify-content: center;
                            }
                        }

                    }
                }

                .images {
                    width: 100%;
                    padding: 0;
                    .images-slider {
                        height: 482px;
                        padding: 0 0 39px;
                        .prev , .next {
                            display: none;
                        }

                        .slick-slider {
                            .slick-list {
                                padding-right: 0;
                                .slick-track {
                                    .slick-slide {
                                        &.slick-current {
                                            >div {
                                                .image {
                                                    img {
                                                        left: 50%;
                                                        transform: translateX(-50%);
                                                        top: 0;
                                                        width: 525px;
                                                        height: 482px;
                                                    }
                                                }

                                            }
                                        }
                                        >div {
                                            .image {

                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .imaged-slider-holder {

                            .image {
                                height: 100%;
                                img {
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    top: 0;
                                    width: 525px;
                                    height: 482px;
                                    opacity: 0;
                                    transition: all 300ms;
                                }
                            }
    					}
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
