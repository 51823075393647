@media (max-width: $screen-lg) {
    // lg-md view
    .item-slider-margin-fix {
        margin-top: 0;
    }

    .items-slider-block.margin-fix {
        .items-slider-block-holder {
            margin-left: 0;
        }
    }

    .items-slider-block.bouquets {
        .items-slider-block-holder {
            .item-slider {

                .slick-slider {
                    .slick-list {
                        padding-bottom: 30px;
                        .slick-track {
                            .slick-slide {
                                >div {

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .items-slider-block {
        margin: 0 0 41px;
        .items-slider-block-holder {
            width: 100%;
            .item-slider {
                padding: 0 0 27px;
                .slick-slider {
                    .slick-list {
                        padding: 4px 2px;
                        width: calc(100% + 4px);
                        margin-left: -2px;
                        padding-bottom: 0;
                        .slick-track {
                            .slick-slide {
                                >div {

                                }
                            }
                        }
                    }
                }

                .heading {
                    margin-bottom: 15px;
                    .text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 150%;
                        width: 100%;
                        max-width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
