@media (max-width: $screen-sm) {
    // sm-view
    .company-points {
        margin: 16px 0 16px;
        &.adjusted-margin {
            margin: 20px 0 24px;
        }
        .company-points-holder {
            width: 100%;
            flex-wrap: wrap;
            .point {
                width: 100%;
                margin: 0 0 13px;
                .point-holder {
                    .icon {

                    }

                    .text {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 130%;
                        padding: 7px 0 0 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
