.delivery-and-payment {
    display: inline-block;
    width: 100%;
    margin: 0 0 80px;
    .delivery-and-payment-holder {
        display: inline-block;
        width: 100%;
        p {

        }

        h3, .h3 {
            font-weight: 600;
            margin: 37px 0 24px;
        }
        h4, .h4 {
            font-weight: 600;
            margin: 6px 0 9px;
            font-style: normal;
            font-size: 21px;
            line-height: 150%;
        }

        .payment-methods {
            display: inline-block;
            width: 100%;
            .payment-method {
                display: inline-block;
                width: 100%;
                margin: 0 0 8px;
                &:last-of-type {
                    margin: 0;
                }
                .icon {
                    display: inline-block;
                    width: 45px;
                    float: left;
                    svg {

                        path {

                        }
                    }
                }

                .text {
                    display: inline-block;
                    float: left;
                    width: calc(100% - 45px);
                    padding: 7px 0 0;
                }
            }
        }

        .page-table {
            display: inline-block;
            width: 100%;
            margin: 0 0 16px;
            table {
                width: 100%;
                border: 1px solid #DFE5EA;
                border-bottom: none;
                border-right: none;
                thead {

                    tr {
                        border-bottom: 1px solid #DFE5EA;
                        th {
                            border-right: 1px solid #DFE5EA;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            color: $gray;
                            background: #F5FCF6;
                            text-align: left;
                            box-sizing: border-box;
                            padding: 9px 0 9px 20px;
                        }
                    }
                }

                tbody {

                    tr {
                        border-bottom: 1px solid #DFE5EA;
                        td {
                            border-right: 1px solid #DFE5EA;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 150%;
                            box-sizing: border-box;
                            padding: 8px 0 7px 20px;
                        }
                    }
                }
            }
        }



        ul.dots {
            margin-bottom: 21px;
            li {
                margin: 0 0 9px;
                p {
                    margin: 0 0 3px;
                    strong {

                    }
                }
            }
        }

        .page-note {
            display: inline-block;
            width: 100%;
            background: #F5FCF6;
            border: 1px dashed #00914C;
            box-sizing: border-box;
            padding: 15px 24px;
            .page-note-holder {
                display: inline-block;
                width: 100%;
                p {

                    strong {

                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/delivery_and_payment";
@import "../../media/mobile/includes/common/delivery_and_payment";
