@media (max-width: $screen-sm) {
    // sm-view
    .positions-list {
        .positions-list-holder {
            .position {
                padding: 20px 20px 20px;
                .position-holder {
                    .main-info {
                        width: 100%;
                        .main-info-holder {
                            padding: 0;
                            .name {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 130%;
                            }

                            .desc {

                            }
                        }
                    }

                    .sub-info {
                        width: 100%;
                        .sub-info-holder {
                            .salary {
                                text-align: left;
                                margin: 0 0 11px;   
                            }

                            .action {
                                .btn {
                                    width: 190px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
