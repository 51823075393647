.toggable-space {
    display: inline-block;
    width: 100%;
    &.open {
        .toggle {
            margin-bottom: 21px;
            a {
                .icon {
                    svg {
                        transform: rotate(0deg);
                        transition: all 300ms;
                    }
                }

                .text {
                    &:after {
                        opacity: 0;
                        transition: all 300ms;
                    }
                }
            }
        }

        .space {
            opacity: 1;
            transition: all 300ms;
        }
    }
    .toggle {
        display: inline-block;
        width: 100%;
        margin-bottom: 0;
        a {
            display: inline-block;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            .icon {
                display: inline-block;
                float: left;
                width: 18px;
                height: 18px;
                padding-top: 3px;
                margin-right: 11px;
                svg {
                    width: 100%;
                    height: 100%;
                    transform: rotate(90deg);
                    transition: all 300ms;
                    path {

                    }
                }
            }

            .text {
                display: inline-block;
                width: calc(100% - 18px - 11px);
                float: left;
                transition: all 300ms;
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 0;
                    border-top: 1px dashed currentColor;
                    position: absolute;
                    left: 0;
                    bottom: -3px;
                    opacity: 1;
                    transition: all 300ms;
                }
            }
        }
    }

    .space {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        opacity: 0;
        transition: all 300ms;
        .space-holder {
            display: inline-block;
            width: 100%;
        }
    }
}

@import "../../media/tablet/includes/common/toggable_space";
@import "../../media/mobile/includes/common/toggable_space";
