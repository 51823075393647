.contacts {
    display: inline-block;
    width: 100%;
    margin-bottom: 31px;
    .contacts-holder {
        display: inline-block;
        width: 100%;
        .infoes {
            display: inline-block;
            float: left;
            width: calc(100% - 635px);
            .infoes-holder {
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding-right: 30px;

                hr {
                    box-sizing: content-box;
                    height: 0;
                    margin: 18px 0 18px;
                    border: none;
                    border-top: 1px solid #DFE5EA;
                }

                p {

                    strong {

                        a {
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                            }
                        }
                    }
                }

                .socials {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin: 16px 0 0;
                    .text {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 150%;
                        margin-right: 11px;
                    }

                    .list {

                        ul {
                            display: inline-block;
                            margin: 0;
                            padding: 0;
                            li {
                                display: inline-block;
                                float: left;
                                margin: 0 5px;
                                a {
                                    &:hover {
                                        opacity: 0.64;
                                    }
                                    svg {

                                    }
                                }
                            }
                        }
                    }
                }

                .shop-list {
                    display: inline-block;
                    width: 100%;
                    margin: 12px 0 0;
                	.shop {
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 19px;
                		.shop-holder {
                            display: inline-block;
                            width: 100%;
                			.icon {
                                display: inline-block;
                                float: left;
                                width: 18px;
                                padding-top: 4px;
                				svg {

                				}
                			}

                			.text {
                                display: inline-block;
                                float: left;
                                width: calc(100% - 18px);
                                box-sizing: border-box;
                                padding: 0 0 0 17px;
                				.address {
                                    display: inline-block;
                                    width: 100%;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 130%;
                                    margin-bottom: 6px;
                				}

                				.phone {
                                    display: inline-block;
                                    width: 100%;
                					a {
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 14px;
                                        line-height: 150%;
                                        color: $gray-base;
                                        &:hover {
                                            color: $brand-primary;
                                        }
                					}
                				}
                			}
                		}
                	}
                }
            }
        }

        .map {
            display: inline-block;
            float: left;
            width: 635px;
            .map-holder {
                display: inline-block;
                width: 100%;
                height: 650px;
                border-radius: 10px;
                overflow: hidden;
                img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/contacts";
@import "../../media/mobile/includes/common/contacts";
