@media (max-width: $screen-lg) {
    // lg-md view
    .wholesale-catalog {
        .wholesale-catalog-holder {
            .wholesale-item {
                width: 100%;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
