@media (max-width: $screen-lg) {
    // lg-md view
    .company-points {
        margin: 31px 0 35px;
        &.adjusted-margin {
            margin: 28px 0 33px;
        }
        .company-points-holder {
            width: calc(100% + 30px);
            .point {
                width: 33.333%;
                .point-holder {
                    .icon {

                    }

                    .text {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 130%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
