.faq {
    display: inline-block;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 29px;
    .faq-holder {
        display: inline-block;
        width: 100%;
        .qa-list {
            display: inline-block;
            width: 100%;
            .qa-element {
                display: inline-block;
                width: 100%;
                margin-bottom: 13px;
                transition: all 300ms;
                &.animated {
                    .qa-element-holder {
                        height: auto;
                        overflow: visible;
                    }
                }
                &.open {
                    transition: all 300ms;
                    .qa-element-holder {
                        transition: all 300ms;
                        a.list-opener {
                            transition: all 300ms;
                            color: $brand-primary;
                            text-decoration-color: transparent;
                            .icon {
                                transition: all 300ms;
                                .icon-holder {
                                    svg {
                                        &.minus {
                                            opacity: 1;
                                            transition: all 300ms;
                                        }
                                        &.plus {
                                            opacity: 0;
                                            transition: all 300ms;
                                        }
                                    }
                                }
                            }
                        }
                        .qa-element-contents {
                            opacity: 1;
                            transform: translateY(0);
                            transition: all 300ms;
                            max-height: 2000px;
                            overflow: hidden;
                            .qa-element-contents-holder {
                                opacity: 1;
                                transition: all 100ms;
                            }
                        }
                    }
                }
                .qa-element-holder {
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    transition: all 300ms;
                    height: auto;
                    overflow: hidden;
                    box-sizing: border-box;
                    a.list-opener {
                        display: flex;
                        width: 100%;
                        height: auto;
                        align-items: center;
                        overflow: hidden;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 21px;
                        line-height: 150%;
                        font-family: $accent_font;
                        color: $gray-base;
                        text-decoration: none;
                        transition: all 300ms;
                        box-sizing: border-box;
                        padding: 0 0 0 44px;
                        position: relative;
                        text-decoration: underline;
                        text-decoration-color: $brand-primary;
                        text-decoration-style: dashed;
                        .icon {
                            width: 23px;
                            height: 23px;
                            object-fit: contain;
                            color: $brand-primary;
                            position: absolute;
                            top: 50%;
                            left: 2px;
                            transform: translateY(-50%);
                            transition: all 300ms;
                            .icon-holder {
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                box-sizing: border-box;
                                position: relative;
                                svg {
                                    position: absolute;
                                    left: -1px;
                                    top: 0;
                                    &.minus {
                                        opacity: 0;
                                        transition: all 300ms;
                                    }
                                    &.plus {
                                        opacity: 1;
                                        transition: all 300ms;
                                    }
                                }
                            }
                        }
                        &:hover {
                            color: $brand-primary;
                            transition: all 300ms;
                        }
                    }
                    .qa-element-contents {
                        display: inline-block;
                        width: 100%;
                        position: relative;
                        left: 0;
                        top: 100%;
                        z-index: -100;
                        overflow: hidden;
                        opacity: 0;
                        transform: translateY(-30px);
                        transition: all 300ms;
                        margin-top: 0;
                        box-sizing: border-box;
                        max-height: 0;
                        overflow: hidden;
                        .qa-element-contents-holder {
                            display: inline-block;
                            width: 100%;
                            opacity: 0;
                            transition: all 100ms;
                            position: relative;
                            box-sizing: border-box;
                            padding: 10px 0 16px 44px;
                            p {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 150%;
                                margin: 0 0 10px;
                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/faq";
@import "../../media/mobile/includes/common/faq";
