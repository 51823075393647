.articles-list {
    display: inline-block;
    width: 100%;
    margin-bottom: 77px;
    .articles-list-holder {
        width: calc(100% + 30px);
        margin-left: -15px;
        .article-item {
            display: inline-block;
            float: left;
            width: 33.333%;
            box-sizing: border-box;
            padding: 0 15px;
            margin-bottom: 27px;
        }
    }
    .inline-page-action {
        margin: 0 0 31px;
    }
}

@import "../../media/tablet/includes/common/articles_list";
@import "../../media/mobile/includes/common/articles_list";
