@media (max-width: $screen-lg) {
    // lg-md view
    .not-found {
        margin: 37px 0 98px;
        .not-found-holder {
            .four-zero-four {
                img {
                    width: 100%;
                    height: 49vw;
                    object-fit: contain;
                }
            }

            .text {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 150%;
                padding: 0 18%;
            }

            .action {
                .btn {

                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
