@media (max-width: $screen-sm) {
    // sm-view
    .items-slider-block {
        margin: 0 0 41px;
        .items-slider-block-holder {
            .item-slider {
                padding: 0 0 27px;
                .slick-slider {
                    .slick-list {
                        .slick-track {
                            .slick-slide {
                                >div {

                                }
                            }
                        }
                    }
                }

                .heading {
                    margin-bottom: 6px;
                    .text {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 21px;
                        line-height: 150%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
