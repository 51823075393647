.not-found {
    display: inline-block;
    width: 100%;
    margin: 19px 0 76px;
    .not-found-holder {
        display: inline-block;
        width: 100%;
        .four-zero-four {
            display: inline-block;
            width: 100%;
            text-align: center;
            img {

            }
        }

        .text {
            display: inline-block;
            width: 100%;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
            box-sizing: border-box;
            padding: 0 24%;
            margin: 0 0 28px;
        }

        .action {
            display: inline-block;
            width: 100%;
            text-align: center;
            .btn {
                font-family: $accent_font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 130%;
                width: 290px;
                text-align: center;
                padding: 15px 0;
                border-radius: 70px;
                border: 1px solid rgba(0, 121, 64, 0.2);
                &:hover {
                    border: 1px solid $brand-primary;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/not_found";
@import "../../media/mobile/includes/common/not_found";
